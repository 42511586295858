.bbri-theme {
  .feedback-content {
    margin-bottom: 10px;
  }

  .feedback-screenshot {
    display: flex;
    align-items: center;

    button {
      margin-right: 10px;
    }
  }

  .feedback-comment {
    margin-bottom: 10px;

    label {
      font-weight: bold;
      margin-left: 5px;
    }

    textarea {
      margin-top: 0;
    }
  }
	.feedback-button-wrapper {
		position: fixed;
		bottom: 20px;
		right: 20px;
		width: 60px;
		height: 60px;
		// box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
		.feedback-button {
			border-radius: 5em;
			border: none;
			cursor: pointer;
			height: 100%;
			width: 100%;
			margin: -1px;
			background-size: 60px;
			background-image: url(https://avatars.collectcdn.com/5e81dcfb080ddb1058a71d00-5e81dcfb080ddb1058a71d01.png?t=1643194955359);
			background-position: center;

			&:hover {
				background-color: lighten(#0087b7, 5%);
				box-shadow: 0 4px 42px 0 #00000040;
			}
		}
	}
}
