.valuesTable {
  .p-datatable {
    width: 60%;
  }
}

.form {
  .param {
    margin: 10px;

    input, .p-dropdown {
      width: 300px;
    }
  }
  label {
    display: inline-block;
    width: 150px;
  }
}

.valuesTable {
  margin-left: 10px;
  .addValue {
    margin: 10px
  }
}

#sizeFieldset {
  width: 60%;
  margin-bottom: 20px;

  .sizeValueNumber, .p-dropdown {
    margin: 5px;
    width: 50%;
  }
}

#addValueDialog {
  margin: 10px;
  .p-dialog-content {
    height: 150px;
    div {
      margin: 5px;
    }
  }
}

.content {
  display: flex;
}
.leftPart {
  display: inline;
  width: 50%;
  margin-right: auto;
}

.rightPart {
  display: inline;
  width: 45%;
  right: 0;
  .questionImages {
    margin: 10px;
  }
  .p-fieldset {
    width: 80%;
    margin-left: 10%;
  }
}

.organizationChart {
  .p-organizationchart-node-content {
    width: 150px;
    background-color: #ededed !important;
  }
}

.p-organizationchart-table {
  td:hover, tr:hover {
    background-color: white;
  }
}

.p-fieldset {
  margin: 10px;
  background-color: #fafafa !important;
}

.tabView {
  width: 100%;
  i, span {
    vertical-align: middle;
  }
  i {
    margin-right: 5px;
  }
}

.additionalParams {
  width: 40%;
}

#conditionsDelete {
  top: 0px;
  width: 100px;
  margin: 5px;
}

.p-node-toggler-icon {
  margin-right: 0 !important;
}

.questionDefaultValue {
  .p-dropdown-filter {
    width: 100% !important;
  }
}

.conditionTextArea {
  background-color: #ebebeb !important;
  background-image: repeating-linear-gradient(0deg, #dedede, #dedede 1px, transparent 1px, transparent 15px),repeating-linear-gradient(-90deg, #dedede, #dedede 1px, transparent 1px, transparent 15px) !important;

  resize: none;
}
