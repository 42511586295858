table {
  border-collapse: collapse;
}

tr {
  border-bottom: 1px solid #DDDDDD;
}

div[id^="portal-root-"] {
  div[class*="Select-module_dropdown__"] {
    li {
      white-space: unset;
    }
  }
}

.content {
  overflow: auto;
}

td, th {
  padding: 8px;
  width: 200px;
}

tbody tr, th {
  word-break: unset;
}

td {
  text-align: center;

  > div {
    margin-bottom: 0.93rem;
  }
}

.number .form-input-group {
  display: inline-block;
}

tr:hover {
  background-color: lightgray;
}

thead {
  border-bottom: darkgray 1px solid;
}

.button button {
  background-color: #0087b7;
  color: white;
  padding: 7px 8px;
  border: none;
  z-index: 1;
  cursor: pointer;
  margin: 10px 10px 0 5px;
}

.button button:hover {
  background-color: lighten(#0087b7, 5%);
}
