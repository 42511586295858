@import '../../../../styles/_var.scss';





.question-selector{
    width: 100%;
    position: relative;
    text-align: center;

  h2 {
    margin: 10px;
  }

    .selector-help{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $bright-color;
        border: 1px solid #e0e0e0;
        height: 50px;
        width: 100%;
        color: $dark-color;

        font-size: 2.2vh;
    }

    .section-selectors{
      flex-direction: row;
      text-align: center;
      .sub-title{
        padding-left:10%;
        display: block;
        text-align: left;
        font-size: 1.7vh;
      }
      .p-dropdown.p-component.p-inputwrapper{
        width: 80%;
        height: 2%;
        margin: 1%;
        .p-dropdown-trigger{
          padding:0.5rem;
        }
        .p-dropdown-label.p-placeholder{
          font-size:2vh;
        }
      }
    }




    .p-dropdown-panel{
        /* left: -155.85px !important;
        transition: left 0.5s cubic-bezier(0.4, 0, 1, 1);
        overflow: auto; */
       /*  margin-right: 40px !important; */
        width: 100px !important;
        left: 0 !important;
    }

    .p-dropdown {
        margin: 10px;
        .p-dropdown-item{
            font-size: 1.7vh;
            overflow: auto;
            white-space: nowrap;
            max-width: 100%;
            box-sizing: content-box;

            &::-webkit-scrollbar {
              height: 0;
            }
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
              background: #888;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
        }


        .p-dropdown-item:hover {
            &::-webkit-scrollbar {
              height: 5px;
            }
        }
    }


}

