.input-form-email {
	width: 76%;
	max-width: 480px;
	border: 1px solid #93aab2;
	border-radius: 3px;
	font-size: 16px;
	padding: 15px 18px;
	margin-top: 16px;
	outline: none;

	&.error {
		border: 1px solid #f30c0c;
		color: #f30c0c;
	}
}

.input-form-error {
	color: #f30c0c;
	margin-right: 3px;
	margin-top: 4px;
}
