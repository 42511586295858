@import '../../styles/_var.scss';

$side-nav-margin-button: 5px;

$left-offset:15em;
/* $offset:2em; */
$complete-space-height: 100vh;
$complete-space-width: 100vw;

$butt-width: 6rem;
$butt-height: 5rem;

$question-selector-width: 18vw;


.editor-script{
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: calc(#{$top-line-height});
    right: 0;
    left: $left-offset;
    bottom: 0;
    overflow: auto;
    height: 96.5%
}


.workPlace{

  display: flex;
  height: 100%;
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
    text-transform: uppercase;
    .p-menuitem-text{
      color: $dark-color;
    }
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: $bright-color;
    border-color: $dark-color;
    color: $dark-color;
  }

      
  .p-button.p-component.p-button.p-button-info.p-m-2:hover{
    color: $bright-color;
    background: $dark-color;
    border: 1px solid $bright-color;
  } 
  .p-button.p-button-info {
    color: $dark-color;
    /* background: $bright-color; */
    background: #ffffff;
    border: 1px solid $dark-color;
    font-weight: $special-font-weight;
    /* height:$butt-height;
    width:$butt-width; */
    height:fit-content;
    width:fit-content;

  }




  .p-card.p-component{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    
    .p-card-body{
      width: inherit;
      height: inherit;
      padding-top: 1px !important;

      .p-card-title{
        height:10%;
      }

      .p-card-content{
        /* 
        position: absolute;
        height: auto; */
        height:94%;
        padding: 0 !important;
        #scriptEditorHTML,
        #scriptEditorJS{
          height: 100%;
          width: 80%;
          justify-content: center;
          margin-top: 5px;

          .p-col-12.p-jc-center#filenameInputHTML,
          .p-col-12.p-jc-center#filenameInputJS{
            /* top: 0 !important;
            position: absolute; */
            height: fit-content;
            max-width: 100%;
            display: flex;
            /* display: grid; */
            /* flex-wrap: nowrap; */
            /* justify-content: space-around; */
            position: relative;
            border-bottom: 1px solid #e9ecef;
            margin-top: 5px;
            .p-float-label{
              width:100%;
              position: absolute;
              top: 0;
              left: 0;
              padding: inherit;
            }
            .InputText-module_main__2FLbt{
              text-align: center;
              display: grid;
              align-items: center;
              justify-content: center;

              .InputText-module_input__-x-xs{
                margin-top: 0;
                height:1.7rem;
                text-align: center;
              }
            }


            .titleCodeEditor{
              display: grid;
              align-items: center;
              font-size: 1.5rem;
              font-weight: 700;
              /* justify-content: center; */
              justify-content: left;
              width:100%;
              position: sticky;

            }
          }

          #editorAreaHTML,
          #editorAreaJS{
            top: calc(#{$top-line-height});

            height:90%;
            width: 100%;

          }

          
          .p-col-12.p-d-flex.p-jc-center#submitButtonCodeEditorHTML,
          .p-col-12.p-d-flex.p-jc-center#submitButtonCodeEditorJS{
            height:fit-content;
            border-top: 1px solid #e9ecef;

          }
        }
    
        #questionSelectorHTML, #questionSelectorJS{
          height:100%;
          width: $question-selector-width;
          z-index: 997;
          /* width: 300px; */

          right: 0;
          border-left: 2px solid $bright-color;
          background-color: #ffffff;
          position: absolute;
          top: calc(#{$tab-menu-height} + #{$top-line-height} + 3px);
          padding: 0 !important;
          border-top: solid 2px $bright-color;

        }
      }
    }
  }
}

#filenameInputHTML {
  text-align: center;
  .titleCodeEditor {
    width: 250px !important;
    position: absolute !important;
    left: 0 !important;
    display: flex !important;
  }
}

#isActiveCheckbox {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
