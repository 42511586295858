
table {
  tbody {
    .form-summary-question {
      cursor: pointer;
    }
  }
}

tbody tr, th {
  word-break: break-word;
}

// Mobile & Tablet
@media (max-width: 420px) {
  .containerSummary {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .containerSummary div {/*
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;*/
  }
}

.tableQuestionSummary{
  margin-bottom: 15px;
}

.summaryTable {
  tr.form-summary-question:nth-child(even) {
    background-color: #ffffff !important;
  }

  tr.form-summary-question:nth-child(odd) {
    background-color: #dcdfe1 !important;
  }

  tr.form-summary-question:hover {
    background-color: #ffd894 !important;
  }
}

.tableQuestionSummary {

  tr:hover {
    background-color: #ffd894 !important;
  }

  tr {
    border: 1px solid #00a9b2;
  }

  thead tr {
    background-color: #dcdfe1 !important;
  }
}
