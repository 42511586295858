.feedback-modal {
  position: fixed;
  bottom: 55px;
  right: 55px;
  z-index: 2;
  background-color: white;
  padding: 20px;
  border-radius: 8px 8px 0 8px;
  width: auto;
  max-width: 80%;
  box-shadow: 1px 1px 5px #959595;

  button {
    font-size: 0.9rem;
  }

  .feedback-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .feedback-modal-body {
    .feedback-screenshot {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .feedback-screenshot-content {
        margin-top: 5px;
        margin-left: 10px;
      }
    }
  }

  .feedback-modal-footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

// Mobile & Tablet
@media (max-width: 768px) {

  .feedback-modal {
    .feedback-modal-body {
      .feedback-screenshot {
        flex-direction: column;
      }
    }
  }
}

