@import '../../styles/_var.scss';

$side-nav-margin-button: 5px;

$left-offset:15em;
/* $offset:2em; */
$complete-space-height: 100vh;
$complete-space-width: 100vw;

$butt-width: 6rem;
$butt-height: 5rem;

$question-selector-width: 18vw;

$margin-top-between-section:4vh;
.editor-script{
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: calc(#{$top-line-height});
    right: 0;
    left: $left-offset;
    bottom: 0;
    overflow: auto;
}


.workPlace#requirementEditorArea{

  display: flex;
  height: 100%;
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
    text-transform: uppercase;
    .p-menuitem-text{
      color: $dark-color;
    }
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: $bright-color;
    border-color: $dark-color;
    color: $dark-color;
  }

      
  .p-button.p-component.p-button.p-button-info.p-m-2:hover{
    color: $bright-color;
    background: $dark-color;
    border: 1px solid $bright-color;
  } 
  .p-button.p-button-info {
    color: $dark-color;
    /* background: $bright-color; */
    background: #ffffff;
    border: 1px solid $dark-color;
    font-weight: $special-font-weight;
    /* height:$butt-height;
    width:$butt-width; */
    height:fit-content;
    width:fit-content;

  }




  .p-card.p-component{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    
    .p-card-body{
      width: inherit;
      height: inherit;
      padding-top: 1px !important;

      .p-card-title{
        height:10%;
      }

      .p-card-content{

        height:100%;
        padding: 0 !important;
        #requirementEditor{
          height: 100%;
          width: 100%;
          justify-content: center;
          align-content: space-between;


          .p-col-12.p-jc-center#requirementName{

            height: fit-content;
            max-width: 100%;
            display: flex;
            position: relative;
            border-bottom: 1px solid #e9ecef;
            margin-top: 5px;
            .p-float-label{
              width:100%;
              position: absolute;
              top: 0;
              left: 0;
              padding: inherit;
            }
            .InputText-module_main__2FLbt{
              text-align: center;
              display: grid;
              align-items: center;
              justify-content: center;

              .InputText-module_input__-x-xs{
                margin-top: 0;
                height:1.7rem;
                text-align: center;
              }
            }


            .titleRequirementEditor{
              display: grid;
              align-items: center;
              font-size: 1.5rem;
              font-weight: 700;
              /* justify-content: center; */
              justify-content: left;
              width:100%;
              position: sticky;
              height: 50px;

            }
          }

          #requirementArea{
            top: calc(#{$top-line-height});
            height:fit-content;
            /* width: 100%; */
            width: 40%;/* COLUMNS */
            margin: 0 20%;
            .inputtext-suffix-prefix, .inputtext-group{
              /* margin-top:10vh; */
              margin-top:$margin-top-between-section;/* COLUMNS */
            }
            .dropdown-answer-requi, .inputtext-suffix-prefix, .inputtext-group{
              display: flex;
              flex-direction: column; /* COLUMNS */
              .dropdown-area, .inputtext-area{
                text-align: center;
                width: 100%;
                padding: 0 2%;
                .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled{
                  width: 100%;
                  margin: 1vh;
                  .p-inputtext{
                    text-align: center;
                  }
                  .p-dropdown-panel{
        
                    width: 20vw !important;
                    left: 0 !important;
                  }
                }
                .sub-title{
                  padding-left:1vh;
                  display: block;
                  text-align: left;
                  font-size: 2.2vh;
                }
                .p-dropdown {
                  /* margin: 1vh; */
                  .p-dropdown-item{
                    font-size: 1.7vh;
                    overflow: auto;
                    white-space: nowrap;
                    max-width: 100%;
                    box-sizing: content-box;
          
                    &::-webkit-scrollbar {
                      height: 0;
                    }
                    &::-webkit-scrollbar-track {
                      background: #f1f1f1;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #888;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                      background: #555;
                    }
                  }
          
          
                  .p-dropdown-item:hover {
                      &::-webkit-scrollbar {
                        height: 5px;
                      }
                  }
                }


                /* input text part */
                .p-float-label .InputText-module_main__2FLbt{
                  margin: 1vh;
                  width: 100% !important;

                  .InputText-module_input__-x-xs{
                    width: 100% !important;

                    max-width: 100% !important;
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    font-size: 16px;
                    padding: 0.5rem !important;
                    margin-top: 0!important;
                    outline: none;
                  }
                }

                /* input text part */
                .p-float-label .p-autocomplete.p-component.p-inputwrapper.p-autocomplete-dd{
                  margin: 1vh;
                  width: 100% !important; 

                  .p-inputtext{
                    text-align: center;
                  }
                }


                .p-float-label .p-autocomplete{
                  .p-autocomplete-panel{
                    width: 100% !important;

                    .p-autocomplete-item{
                      font-size: 1.7vh;
                      overflow: auto;
                      white-space: nowrap;
                      max-width: 100%;
                      box-sizing: content-box;
              
                      &::-webkit-scrollbar {
                        height: 0;
                      }
                      &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                      }
                      &::-webkit-scrollbar-thumb {
                        background: #888;
                      }
                      &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                      }
                    }
              
                    .p-autocomplete-item:hover {
                        &::-webkit-scrollbar {
                          height: 5px;
                        }
                    }
                  }

                  .p-button.p-component.p-autocomplete-dropdown.p-button-icon-only{
                    background-color: #7B848B !important;
                    border-color: #7B848B !important;

                  }
                }                
              }
            }
            .checkbox-uppercase{
              margin-top:$margin-top-between-section !important;
              text-align: center;
              .sub-title{
                display: block;
                font-size: 2.2vh;
                margin-bottom:1vh;
              }

              .p-button.p-togglebutton.p-component.p-highlight{
                background: $bright-color !important;
                border-color: $bright-color !important;
                color: $dark-color !important;
                .p-button-icon.p-c.p-button-icon-left.pi.pi-check{
                  color: $dark-color !important;
                }
              }
            }
          }

          .p-col-12.p-d-flex.p-jc-center#submitButtonRequirementEditor{
            height:fit-content;
            border-top: 1px solid #e9ecef;

          }
        }
      }
    }
  }
}

.requirementInput {
  margin: 0 !important;
}

.toolbarCenterRequirement  {
  position: absolute;
  left: 40%;
  display: flex;
  align-items: center;
}
