@import "../../styles/var";

.bbri-theme {
  .about {
    margin-right: 15px;
  }

  .compact {
    display: none;
  }
}

@media (max-width: 576px) {
  .bbri-theme {
    .about {
      margin-right: 5px;
      padding: 0 5px;

      button {
        padding: 0 5px;
        height: 30px;
        line-height: 30px;
      }

      .compact {
        display: block;
        padding-left: 5px;
        padding-right: 5px;
      }

      .full {
        display: none;
      }
    }
  }
}
