@import "../RequirementEditor.scss";
@import "../../sidebars.scss";


.sideBar{
    border-right: $side-border-right solid $bright-color;
    bottom: 0;
    background: #fff;
    color: #333;
    display: block;
    font-family: "RobotoDraft", sans-serif;
    left: 0;
    position: fixed;
    top: $top-line-height;
    width: 15em;
    z-index: 998;
    top: calc(#{$tab-menu-height} + #{$top-line-height}); 


  .p-datatable-scrollable-header-box{
    margin-right: 0 !important;
  }



    /* 
    1. Code above overrides classes from react component Table and Button
    */
  $side-nav-margin-button: 5px;

  .p-datatable-thead{
    tr{
      th {
        width: 0;
        transform: translateX(-4px); /* TODO Search for another option for --> .p-inputtext .p-component .p-column-filter */
      }
    }
  }

  .p-datatable-scrollable-header-table .table-custom{
    width: 100%;
  }
  .p-datatable{
    .p-datatable-tbody > tr{
      width: 100%;
    }

    .p-datatable-scrollable-body-table .table-custom{
      width: 100% !important;
    }

  }

  .p-datatable .p-datatable-tbody > tr{

  }
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: center;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 0.1rem 1px;
    align-content: center;

    width: fit-content;
    font-size: 1.7vh;

    /* overflow: hidden; */
    overflow: auto;
    white-space: nowrap;
    max-width: 100%;
    box-sizing: content-box;
    /* transform: translateX(0);
    transition: 1s; */

    /* &::-webkit-scrollbar {
      display: none;
    } */

    &::-webkit-scrollbar {
      /* display: block;
      visibility: visible; */
      height: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td:hover {


    &::-webkit-scrollbar {
      /* display: block;
      visibility: visible; */
      height: 5px;
    }
  }


  .p-datatable-tbody > tr > .p-selection-column{
    text-align: center !important;
  }
  .p-datatable .p-datatable-thead > tr > th{
    padding:  5px;
    text-align: center;
  }
  
  .p-datatable-tbody{
    tr:last-child{
        margin-bottom: 100px;
    }
  }
  td > div {
    margin-bottom: 0;
  }
  button{
    margin-right: $side-nav-margin-button;
  }
  .p-toolbar {
    justify-content: center;
  }
  

  .p-toolbar.p-component{
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 19%;

  }

  .p-datatable.p-component.p-datatable-scrollable.p-datatable-hoverable-rows{
    height: 75%;

    .p-datatable-scrollable-wrapper{
      height: 100%;
      .p-datatable-scrollable-view{
        height: 100%;
        .p-datatable-scrollable-header{
          height: fit-content;
        }

        .p-datatable-scrollable-body{
          height: fit-content !important;
          overflow-y: scroll !important;
          visibility: visible !important;
          max-height: 90% !important;
        }
      }
    }
  }

  .p-toolbar-group-left, .p-toolbar-group-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .p-fileupload.p-fileupload-basic.p-component{
    margin: $side-nav-margin-button;
  }

  /* 
    1. Code above overrides classes from react component Table and Button
  */

  
  .p-datatable .p-datatable-tbody > tr.p-highlight { 
    background: $bright-color; 
    /* background: $dark-color; */
    color: $dark-color; 
    transition: font-size 0.2s cubic-bezier(0.4, 0, 1, 1), background 0.2s cubic-bezier(0.4, 0, 1, 1); 
    font-weight: $special-font-weight;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: $bright-color;/* #FEC418; */
    border-color: #6c757d;
    color: #6c757d;
  }
  
  
  .p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: $dark-color;
  }
  
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $dark-color;
  }
}

.editor-script .p-fileupload-choose {
  background-color: #0087b7 !important;
  border-color: #0087b7 !important;
}
