/*
 * BBRI UI: Component Styles
 */
.bbri-theme {
  // = Content
  [class^="Content-module_content__"] {
    margin-top: 0;
    padding: 0;
  }

  // = Question
  [class^="Question-module_question__"] {
    margin-bottom: 0;
    padding: 1.5rem;

    [class^="Question-module_content__"] {
      width: 100%;
    }

    [class^="Question-module_title__"] {
      margin-bottom: 10px;
    }
  }

  // = Panel
  [class^="Panel-module_panel__"] {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 1.5rem;
  }

  // = Radio
  [class^="Radio-module_container__"]
  [class^="Radio-module_checkmark__"]::after {
    top: 4px;
    left: 4px;
  }

  // = InputNumeric
  [class^="InputNumeric-module_numeric__"] {
    input[type="number"] {
      width: calc(136px - 50px);
    }
  }

  // = InputTextarea
  [class^="InputTextArea-module_main__"] {
    [class^="InputTextArea-module_textarea__"] {
      width: 100%;
      max-width: none;
    }
  }

  // = Dropdown
  [class^="Select-module_controls__"] > svg:last-of-type {
    box-sizing: initial;
  }

  button[class*="Button-module_primary__"] {
    background-color: #8dad35;
  }

  button[class*="Button-module_primary__"]:hover {
    background-color: #B7D05A;
  }

  button[class*="Button-module_secondary__"] {
    border: 1px solid #005979;
    color: #005979;
  }

  div[class^="Select-module_input__"] {
    border: 1px solid #005979;
  }

  .summaryTable tr.form-summary-question:hover {
    background-color: lighten($primary-color, 50%) !important;
  }

  table[class*="Table-module_table__"] thead th {
    border-bottom: solid 2px #0087b7;
  }

  div[class*="Question-module_question__"] {
    border-left: 4px solid #005979;
  }

  // = Modal
  [class^="Modal-module_modal__"] {
    display: flex;
    flex-direction: column;
    padding: 20px;

    [class^="Modal-module_spacer__"] {
      padding: 0;
    }
  }

  // = Table
  [class^="Table-module_table__"] {
    tbody {
      td {
        padding: 5px;
      }
    }
  }

  .text-danger {
    color: $danger-color
  }

  @media (max-width: 768px) {
    [class^="Modal-module_modal__"] {
      width: 100vw;
      min-height: 100vh;
      height: 100vh;

      [class^="Modal-module_body__"] {
        height: 100%;
        max-height: 100%;
      }
    }
  }
}
