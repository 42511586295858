@import "../styles/var";

.form-question-input, .form-question-info {
  margin-bottom: 10px;
}

.form-actions-wrapper{
  padding-bottom: 75px;
}

.form-question-suggestion {
  margin-bottom: 10px;
}

// Mobile & Tablet
@media (max-width: 420px) {
  .form-actions-wrapper{
    //padding-bottom: 75px;
  }
  .form-actions-wrapper button {
    font-size: 0.7rem;
  }
}

.sub, .sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.sub {
  bottom: -0.25em;
}

.sup {
  top: -0.5em;
}
