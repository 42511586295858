@import '../styles/_var.scss';

$left-offset:15em;
$offset:2em;


$icon-size-values-width : 100%;
$icon-size-values-height : 100%;
$icon-outer-w : 2rem;
$icon-outer-h : 100%;

$action-quick-transition: 0.2s cubic-bezier(0.4, 0, 1, 1);


.container {
  padding-top: 50px;
}

.mainDecisionTreeEditor{
  width: 100%;
  height: 100%;
  .topLigne {
    height: $top-line-height;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: $bright-color;
  }
  .tabMenu {
    margin: 0;
    margin-top: $top-line-height;
    border-right: $side-border-right solid $bright-color;
    position: fixed;
    width: $left-offset;
    height: $tab-menu-height;
    z-index: 998;

    .p-tabmenu.p-component {
      height: 100%;
    }

    .p-tabmenu-nav {
      justify-content: space-evenly;
      height: 100%;
    }

    li.p-tabmenuitem {
      margin-bottom: 10px;
      height: 100%;

      .p-menuitem-link {
        justify-content: center;
        padding: 0;
        width: $icon-outer-w;
        height: $icon-outer-h;
        /* position: relative !important; */

        

        .formIcon {
          //background:url('https://img.icons8.com/material-outlined/24/000000/form.png');
          background: url('../assets/form.svg');

        }

        .requirementIcon {
          //background:url('https://img.icons8.com/material-outlined/24/000000/form.png');
          background: url('../assets/requirement.svg');

        }

        .treeIcon {
          //background:url('https://img.icons8.com/material-outlined/24/000000/tree-structure.png');
          background: url('../assets/tree.svg');

        }

        .scriptIcon {
          //background:url('https://img.icons8.com/material-outlined/24/000000/code.png');
          background: url('../assets/code.svg');

        }

        .htmlIcon {
          //background:url('https://img.icons8.com/material-outlined/24/000000/html.png');
          background: url('../assets/html.svg');

        }

        .formIcon, .requirementIcon, .treeIcon, .scriptIcon, .htmlIcon {
          width: $icon-size-values-width;
          height: $icon-size-values-height;
          background-repeat: no-repeat;
          background-position: center;
          // transform: scale(1.5);
          /* position: absolute; */

        }
      }
    }
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      border-color: $dark-color;
      transition: border-color $action-quick-transition, border-bottom-right-radius $action-quick-transition, border-bottom-left-radius $action-quick-transition/* , background $action-quick-transition */;
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
      margin-right: 0;

    }
  }
}

.editorTitle {
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: grid;
  align-items: center;
  font-size: 25px;
  font-weight: 700;
  /* justify-content: center; */
  justify-content: left;
  width:100%;
  position: sticky;
}

.toolbarCenter {
  position: absolute;
  left: 38%;
  display: flex;
  align-items: center;
  max-width: 30%;
  text-overflow: unset;
  white-space: nowrap;

  p {
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  p:hover{
    overflow: visible;
    white-space: normal;
    height:auto;  /* just added this line */
  }
}
